import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'YXYM',
    component: () => import('../views/YXYM.vue')
  },
  {
    path: '/RXXZ',
    name: 'RXXZ',
    component: () => import('../views/RXXZ.vue')
  },
  {
    path: '/ZZZC',
    name: 'ZZZC',
    component: () => import('../views/ZZZC.vue')
  },
  {
    path: '/WSBD',
    name: 'WSBD',
    component: () => import('../views/WSBD.vue')
  },
  {
    path: '/TBZN',
    name: 'TBZN',
    component: () => import('../views/TBZN.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
